.gallery {
    display: flex;
    justify-content: center;
    column-gap: 12px;
    width: 100%;
    @media (max-width: 749px){
        column-gap: 7px;
    }

    // .gallery__column
    &__column {
        flex: 0 1 33%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        @media (max-width: 749px){
            flex: 0 1 100%;
            row-gap: 7px;
        }

        & img{
            object-fit: cover;
            width: 100%;
            height: auto;
            pointer-events:none;
        }
    }
}

.anime{
    animation: fade infinite 2s;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.loading-img{
    animation: loading-img 1s;
}

@keyframes loading-img {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}